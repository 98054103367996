<template>
<div class="nav">
  <div class="w nav-content">
    <div class="nav-left">
      <div class="img_box" @click="navClickHandle">
        <img src="@/assets/images/logo.png" alt="">
      </div>
    </div>
    <div class="nav-right">
      <span>首页</span>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'useNav',
  mounted () {

  },
  methods:{
    navClickHandle(){
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.nav{
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 66;
  .nav-content{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav-left{
      height: 50px;
      .img_box{
        height: 50px;
        line-height: 50px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .nav-right{
      cursor: pointer;
      &:hover{
        color: $textHoverColor;
      }
    }
  }
}
@media screen and (max-width: 750px){
  .nav .nav-content .nav-left .img_box{
    width: 50px;
  }
}
</style>
