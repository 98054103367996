<template>
  <div class="home">
    <!-- 导航 -->
    <use-nav></use-nav>
    <!--轮播-->
    <div class="w use_carousel">
      <van-swipe class="m_swiper" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in swiperData" :key="item.id">
          <img :src="item.img" alt="">
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="w rent clearfix">
      <div class="rent_left">
        <img src="@/assets/images/rent_bg_1.jpg" alt="">
      </div>
      <div class="rent_right clearfix">
        <div class="code">
          <div class="code_box">
            <h3>支付宝小程序</h3>
            <div class="code_img_box mp">
              <img src="@/assets/images/download/mp.png" alt="">
            </div>
          </div>
          <div class="code_box">
            <h3>微信小程序</h3>
            <div class="code_img_box">
              <img src="@/assets/images/download/wx.jpg" alt="">
            </div>
          </div>
        </div>
        <div class="code">
          <div class="code_box">
            <h3>下载IOS版APP</h3>
            <div class="code_img_box">
              <img src="@/assets/images/download/IOS_download.png" alt="">
            </div>
          </div>
          <div class="code_box">
            <h3>下载Android版APP</h3>
            <div class="code_img_box">
              <img src="@/assets/images/download/Android_download.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w text_module">
      <div class="title">
        乐优企业0押金租电脑
      </div>
      <div class="text">
        0押金 / 按月付 / 1台起租 / 全程保修
      </div>
    </div>

    <div class="w video_img_module">
      <div class="img_box">
        <img src="@/assets/images/video_img.jpg" alt="">
      </div>
    </div>
    <!-- 合作 -->
    <div class="w text_module he">
      <div class="title">
        乐优合作单位
      </div>
      <div class="text">
        中国免押办公设备租赁行业知名品牌
      </div>
    </div>
    <div class="w img_box_module">
      <div class="img_box_item">
        <img src="@/assets/images/hezuo/h-1.jpg" alt="">
      </div>
      <div class="img_box_item">
        <img src="@/assets/images/hezuo/h-2.jpg" alt="">
      </div>
      <div class="img_box_item">
        <img src="@/assets/images/hezuo/h-3.jpg" alt="">
      </div>
      <div class="img_box_item">
        <img src="@/assets/images/hezuo/h-4.jpg" alt="">
      </div>
      <div class="img_box_item">
        <img src="@/assets/images/hezuo/h-5.jpg" alt="">
      </div>
      <div class="img_box_item">
        <img src="@/assets/images/hezuo/h-6.jpg" alt="">
      </div>
    </div>

    <div class="w footer_module">
        <div class="header">
          <div class="header_item">
            <div class="left">
              <i class="iconfont icon-dunpai"></i>
            </div>
            <div class="right">
              <p>协助维权</p>
              <p>蒙受经济损失，可申请百度协助</p>
            </div>
          </div>
          <div class="header_item">
            <div class="left">
              <i class="iconfont icon-jiaoyi"></i>
            </div>
            <div class="right">
              <p>虚假赔偿</p>
              <p>遇到品牌或资质冒用，可申请百度保障</p>
            </div>
          </div>
          <div class="header_item">
            <div class="left">
              <i class="iconfont icon-fengkonganquandunpai"></i>
            </div>
            <div class="right">
              <p>欺诈赔偿</p>
              <p>遇到欺诈，经核查属实，可申请保障退还费用</p>
            </div>
          </div>
        </div>
      <div class="text">
        <p>地址：天津市滨海新区杭州道街道燕莎公寓49号 </p>
        <p>Copyright © 天津优租网络科技有限公司</p>
        <p>
          <img src="@/assets/images/20211209171324_15993.png" alt="">
          <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" >津ICP备2021008642号-1</a>
        </p>

        <p>投诉电话：(022) 2299 1297</p>
      </div>
    </div>

  </div>
</template>

<script>
import useNav from '../components/nav/nav'
import swiper1 from '@/assets/images/swiper/swiper-1.jpg'
import swiper2 from '@/assets/images/swiper/swiper-2.jpg'

export default {
  name: 'Home',
  components: { useNav },
  data(){
    return {
      swiperData:[
        {
          id: 1,
          img: swiper1
        },
        {
          id: 2,
          img: swiper2
        },
      ]
    }
  }
}
</script>

<style lang="scss">
@import "../assets/fonts/iconfont.css";
/*  轮播样式*/
.use_carousel{
  margin-top: 80px!important;
  img{
    width: 100%;
  }
}
a:visited{
	color: #666;
}
/* 企业租 */
.rent{

  > div{
    width: 50%;
    float: left;
    &:first-child{
      img{
        width: 100%;
        height: 100%;
      }
    }
    &:last-child{
      padding-top: 45px;
      .code{
        display: flex;
        justify-content: space-between;
        &:first-child{
          margin-bottom: 20px;
          h3{
            padding-bottom: 10px;
          }
        }
        .code_box{
          width: 170px;
          h3{
            font-size: 20px;
            text-align: center;
          }
          .code_img_box{
            width: 100%;
            img{
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px){
  .rent{
    > div {
      &:first-child{
        width: 100%;
      }
      &:last-child{
        width: 100%;
        padding-top: 0;
        .code{
          width: 100%;
          .code_box{
            width: 150px;
            h3{
              font-size: 16px;
              text-align: center;
            }
            .code_img_box{
              width: 100%;
              &.mp{
                height: 70%;
                img{
                  height: 100%;
                }
              }
              img{
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.text_module{
  padding-bottom: 20px!important;
  border-bottom: 1px solid #333;
  > div{
    text-align: center;
    font-size: 30px;
    line-height: 1.5;
    &:last-child{
      font-size: 18px;
      color: #999;
    }
  }
}

.video_img_module{
  .img_box{
    width: 100%;
    img{
      width: 100%;
    }
  }
}

.he{
  margin-top: 30px!important;
  border-bottom: 0!important;
}
.img_box_module{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .img_box_item{

    img{
      width: 100%;
    }
  }
}

.footer_module{
  width: 100%;
  margin-top: 20px!important;
  background-color: rgb(248, 248, 248);
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 40px 0;
    .header_item{
      display: flex;
      align-items: center;
      margin: 10px 0;
      .left{
        i{
          font-size: 4em;
        }
      }
      .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10px;
        p{
          &:first-child{
            margin-bottom: 8px;
          }
          &:last-child{
            color: #999;
          }
        }
      }
    }
  }
  .text{
    width: 100%;
    padding-bottom: 20px;
    p{
      text-align: center;
      font-size: 15px;
      color: #666;
    }
  }
}
</style>
